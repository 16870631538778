.getfirewall-wrapper {
    position: relative;
    /* min-height: 230px;
    display: flex; */
    /* flex-wrap: wrap; Allow items to wrap properly */
    /* align-items: stretch; Ensure equal height distribution */
    /* gap: 10px; Add spacing between items */
}

.getfirewall { 
    /* height: 70%; */
    overflow-y: scroll; 
    box-sizing: border-box;
}

/* .getfirewall::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;  
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;  
} */
 