 
  .select-cloud {
    display: flex;
    padding: 5px;
    width:100%;
    /* background-color: #fff; */
  }
  .select-cloud:hover, .select-cloud:focus {
    /* background-color: #f1faff; */
    color: #009ef7;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
  
  @-webkit-keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }
  
  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }