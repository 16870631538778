.active-plan{
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
  border: 2px solid #3498db !important;
}

.cr-plan:hover{
    box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
    border: 2px solid #3498db !important;
  }

  
  
  .rcard {
    content: "";
    position: absolute; 
    background-image: url('/public/assets/images/container-registry.jpg'); /* Path to your watermark image */
    /* opacity: 0.3;   */
    background-size: cover;
    background-position: center;
    /* z-index: -1;   */
  }
  
  .opn_drawer {
    display: block !important;
  }



