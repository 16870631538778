/* Default styles for small screens (less than 768px) */
.responsive-margin-k8s-deploy {
    margin-bottom: 5.5rem;
  }
  
  .responsive-width-k8s-deploy {
    width: 80%;
  }
  
  /* Media query for screens 768px and wider */
  @media (min-width: 768px) {
    .responsive-margin-k8s-deploy {
      margin-bottom: 0;
    }
  
    .responsive-width-k8s-deploy {
      width: 50%;
    }
  }
  
   /* Base Card Design */
.tick-parent {
  cursor: pointer;
  text-align: center;
  /* background: #ffffff; */
  /* transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out; Remove border transition */
  border: 2px solid transparent;
  align-items: center;
  position: relative;
  border-radius: 3px;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); */
}

/* .tick-parent.plan_list_active {
  border-color: #009ef7 !important;
} */

/* ✅ Tick Mark Circle */
.plan-check-mark-circle {
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #0d6efd 0%, #009ef7 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, background 0.3s;
  opacity: 0;
  transform: scale(0.8);
}

/* ✅ When Selected, Show the Tick */
.tick-parent.plan_list_active .plan-check-mark-circle {
  opacity: 1;
  transform: scale(1);
}

/* ✅ Pulse Effect */
@keyframes pulseEffect {
  0% {
      transform: scale(1);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
  50% {
      transform: scale(1.2);
      box-shadow: 0px 4px 12px rgba(13, 110, 253, 0.5);
  }
  100% {
      transform: scale(1);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
}

.tick-parent.plan_list_active .plan-check-mark-circle {
  animation: pulseEffect 0.6s ease-in-out;
}

/* ✅ Tick Icon */
.plan-check-mark-circle svg {
  stroke: white;
  width: 14px;
  height: 14px;
}

/* ✅ Hover Effect for Smooth Transition */
.tick-parent:hover .plan-check-mark-circle {
  transform: scale(1.15);
}