
.vpc-wrapper {
    position: relative;
    height: 230px; 
    overflow: unset; 
}

.vpc { 
    height: 100%;
    overflow-y: scroll; 
    box-sizing: border-box;
}

.vpc::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;  
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;  
}
 