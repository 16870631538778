.blurred-div {
    position: relative; 
    overflow: hidden; 
  }
  
  .blurred-div::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Adjust this height for the blurred area */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    filter: blur(8px); /* Adjust blur intensity */
    pointer-events: none;
  }
  
  #searchBox::placeholder {
    color: #9899ac !important;
    opacity: 1; /* Ensures full visibility */
  }

  .search-container {
    border-bottom: 0.5px solid #31323c;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  .search-container:hover {
    border-bottom: 0.5px solid #4c4e6f; /* Darker border on hover */
  }
  
  .search-icon {
    font-size: 17px;
    margin-left: 5px;
    margin-right: 6px;
    vertical-align: text-top;
    color: #9899ac;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .search-container:hover .search-icon {
    color: #4c4e6f; /* Change color on hover */
    transform: scale(1.09); /* Slightly increase size */
  }
  
  #searchBox::placeholder {
    color: #9899ac !important;
    opacity: 1;
  }
  