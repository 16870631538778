.fade-out-front {
    animation: fadeOutAnimation 1s forwards;
    opacity: 1;
    transform-origin: bottom;
    display: none;
  }
  
  @keyframes fadeOutAnimation {
    to {
      opacity: 0;
      transform: scaleY(0);
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }