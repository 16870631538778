.label {
    height: 60.68px !important; 
  }

.shadowBox {
  box-shadow:-2px 5px 10px 5px rgb(37 45 51 / 17%), 5px -3px 18px 5px rgb(37 45 51 / 17%);
}

p.no-margin {
  margin-bottom: 0; /* or margin-bottom: auto; */
}

.hrLine{
  border: none !important;
  width: 41 !important;
  height: 2 !important;
  color: "#007bfc" !important;
  background-color: "#007bfc" !important;
  margin: 0 auto !important;
}

.activeBorder{
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
  border:2px solid #3498db !important;
}

.w100{
  width: 100% !important;
}

.mhc_res_size{
  width: 828px;
}

.mhc_checkout2{
    z-index: 95 !important;
    position: fixed !important;
    top: 97px !important;
    width: 268px !important;
    left: 1151.8px !important;
}
.mhc_checkout{ 
  position: fixed !important; 
}
.plan_list_active{ 
  outline: 2px solid #009EF7; 
}
 

.dc-card-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.dc-card {
  flex-basis: 33.33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  overflow: hidden; /* Ensures content exceeding the card's height is hidden */
}

.tableTrBottomBorder{
  border-bottom: 1px solid #e6e6e6 !important;
  border-style: dashed !important;
}

/* Base Card Design */
.data-center-card {
  height: 63px;
  cursor: pointer;
  text-align: center;
  background: #ffffff;
  /* transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out; Remove border transition */
  border: 2px solid transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.data-center-card:hover {
  /* transform: translateY(-2px); */
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); */
}

.data-center-card.active {
  border-color: #009ef7 !important;
  /* box-shadow: 0px 0px 12px rgba(13, 110, 253, 0.5); */
}

/* ✅ Tick Mark Circle */
.check-mark-circle {
  width: 21px;
  height: 21px;
  background: linear-gradient(135deg, #0d6efd 0%, #009ef7 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, background 0.3s;
  opacity: 0;
  transform: scale(0.8);
}

/* ✅ When Selected, Show the Tick */
.data-center-card.active .check-mark-circle {
  opacity: 1;
  transform: scale(1);
  /* transition: none; Remove delay */
}

/* ✅ Pulse Effect */
@keyframes pulseEffect {
  0% {
      transform: scale(1);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
  50% {
      transform: scale(1.2);
      box-shadow: 0px 4px 12px rgba(13, 110, 253, 0.5);
  }
  100% {
      transform: scale(1);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
}

.data-center-card.active .check-mark-circle {
  animation: pulseEffect 0.6s ease-in-out;
}

/* ✅ Tick Icon */
.check-mark-circle svg {
  stroke: white;
  width: 14px;
  height: 14px;
}

/* ✅ Hover Effect for Smooth Transition */
.data-center-card:hover .check-mark-circle {
  transform: scale(1.15);
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px; /* Space between dot and city */
  animation: blink 1.2s infinite;
  position: relative;
  top: -5px; /* Move slightly up */
}

/* Green for Active */
.status-dot.active {
  background-color: #28a745;
}

/* Yellow for Maintenance */
.status-dot.maintenance {
  background-color: #ffc107;
}

/* Blinking Effect */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
}