@keyframes slideLeftToRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

/* Apply the animation to your menu */
#kt_header_menu.slide-animation {
  animation: slideLeftToRight 1s ease-in-out;
  /* Adjust the duration and timing function as needed */
}

.input-group-text {
  background-color: white;
}


.list-item {
  cursor: pointer;

}

.list-item:hover {
  background-color: #f1faff;
}

.list-item:hover a {
  color: #007bff !important;
}

.list-item:hover i {
  color: #007bff !important;
}

.custom-menu {
  border: 1px solid blue;
}


#svg-chart:hover rect {
  fill: #57bffc;
}

#svg-msg:hover rect {
  fill: #57bffc;
}

#svg-api:hover path {
  fill: #57bffc;

}

#svg-current-usage:hover path{
  fill: #57bffc;
}

#ticket-icon:hover path{
  fill: #57bffc;
}
