.GOOGLE button {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
  
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: 0px !important;
    padding: 0px;
    border-radius: 2px;
    border: 0px solid transparent !important;
    font-size: 0.9vw;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  button.githubbutton.btn {
    position: relative;
    top: -0.7rem;
    margin-left: 24px;
}
  button.GOOGLE div {
    background-color: #f94033 !important;
    display: none;
    border-radius: 25px;
  }
  
  .GOOGLE {
    background-color: #f94033 !important;
    width: 20vw;
    margin-bottom: 10px;
    height: 4.5vh;
    text-align: center !important;
    border-radius: 25px !important;
    border: 0 !important;
  }
  .GOOGLE span {
    font-size: 0.9vw !important;
    color: #eaebed;
    /* font-family: Helvetica,sans-serif !important; */
    font-weight: 600 !important;
  
    margin: auto;
  }