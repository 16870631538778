.ribbonCustom {
    padding: 0px 8px !important;
    min-width: 25px !important;
    min-height: 20px !important; 
    right: -10px !important;
}

.plan-overlay-container {
    position: relative;
  }
  
.plan-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(225, 222, 222, 0.45); /* semi-transparent background */
color: #8e8e8e;
display: flex;
justify-content: center;
align-items: center;
font-size: 1em;
z-index: 2;
pointer-events: none; /* allows clicks to pass through the overlay */
}
  
@media only screen and (max-width: 767px) {
    .custom-grid {
      display: grid;
      text-align: center;
    }
    .mobile-text-center{
        text-align: center !important;
    }
    .ribbon-top {
        top: 3 !important;
    }
  }

  /* Base Card Design */
.tick-parent {
  cursor: pointer;
  text-align: center;
  background: #ffffff;
  /* transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out; Remove border transition */
  border: 2px solid transparent;
  align-items: center;
  position: relative;
  border-radius: 3px;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); */
}

.tick-parent.plan_list_active {
  border-color: #009ef7 !important;
}

/* ✅ Tick Mark Circle */
.plan-check-mark-circle {
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #0d6efd 0%, #009ef7 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, background 0.3s;
  opacity: 0;
  transform: scale(0.8);
}

/* ✅ When Selected, Show the Tick */
.tick-parent.plan_list_active .plan-check-mark-circle {
  opacity: 1;
  transform: scale(1);
}

/* ✅ Pulse Effect */
@keyframes pulseEffect {
  0% {
      transform: scale(1);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
  50% {
      transform: scale(1.2);
      box-shadow: 0px 4px 12px rgba(13, 110, 253, 0.5);
  }
  100% {
      transform: scale(1);
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  }
}

.tick-parent.plan_list_active .plan-check-mark-circle {
  animation: pulseEffect 0.6s ease-in-out;
}

/* ✅ Tick Icon */
.plan-check-mark-circle svg {
  stroke: white;
  width: 14px;
  height: 14px;
}

/* ✅ Hover Effect for Smooth Transition */
.tick-parent:hover .plan-check-mark-circle {
  transform: scale(1.15);
}