.loaderComponent{
width: 100px;
height: 100px;
border-radius: 50%;
position: absolute;
animation: loaderComponent-rotate 1s infinite cubic-bezier(0.62, 0.49, 0.52, 0.32);
transform: skew(20deg, 20deg);
transform-origin: center;
}
.mainComponent-div{
    /* text-align: center; */
position: absolute;
left: 55%;
top: 40%;
width: auto;
z-index: 1;
}
.loaderComponent-icon1{
border-bottom: 5px solid #1a1a27;
rotate: 4deg;
}
.loaderComponent-icon2{
border-bottom: 5px solid #1a1a27;
rotate: 129deg;
}

.loaderComponent-icon3{
border-bottom: 5px solid #1a1a27;
rotate: 272deg;
}

.textComponent {
color: #1a1a27;
position: relative;
top: 37px;
left: 22px;
}
@keyframes loaderComponent-rotate{
0%{
transform: skew(20deg, 20deg) rotate(0deg)
}
100%{
    transform: skew(20deg, 20deg) rotate(360deg)
}
}


/* Table Loader Start */

.preloader {
    position: relative;
    width: 65px;
    margin: 6em auto;
  }
  .preloader span {
    position: absolute;
    display: block;
    bottom: 0;
    width: 9px;
    height: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-animation: preloader 2s infinite ease-in-out;
            animation: preloader 2s infinite ease-in-out;
  }
  .preloader span:nth-child(2) {
    left: 11px;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
  }
  .preloader span:nth-child(3) {
    left: 22px;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
  }
  .preloader span:nth-child(4) {
    left: 33px;
    -webkit-animation-delay: 600ms;
            animation-delay: 600ms;
  }
  .preloader span:nth-child(5) {
    left: 44px;
    -webkit-animation-delay: 800ms;
            animation-delay: 800ms;
  }
  .preloader span:nth-child(6) {
    left: 55px;
    -webkit-animation-delay: 1000ms;
            animation-delay: 1000ms;
  }
  
  @-webkit-keyframes preloader {
    0% {
      height: 5px;
      transform: translateY(0);
      background: rgba(0, 0, 0, 0.1);
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      background: #1a1a27;
    }
    50%, 100% {
      height: 5px;
      transform: translateY(0);
      background: rgba(0, 0, 0, 0.1);
    }
  } 
  
  @keyframes preloader {
    0% {
      height: 5px;
      transform: translateY(0);
      background: rgba(0, 0, 0, 0.1);
    }
    25% {
      height: 30px;
      transform: translateY(15px);
      background: #1a1a27;
    }
    50%, 100% {
      height: 5px;
      transform: translateY(0);
      background: rgba(0, 0, 0, 0.1);
    }
  }



  /* Table Loader Light Start */

.preloaderlight {
  position: relative;
  width: 65px;
  margin: 6em auto;
}
.preloaderlight span {
  position: absolute;
  display: block;
  bottom: 0;
  width: 9px;
  height: 5px;
  border-radius: 5px;
  background: #fff;
  -webkit-animation: preloader 2s infinite ease-in-out;
          animation: preloader 2s infinite ease-in-out;
}
.preloaderlight span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.preloaderlight span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}
.preloaderlight span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}
.preloaderlight span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms;
}
.preloaderlight span:nth-child(6) {
  left: 55px;
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}

@-webkit-keyframes preloaderlight {
  0% {
    height: 5px;
    transform: translateY(0);
    background: #fff;
  }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: #fff;
  }
  50%, 100% {
    height: 5px;
    transform: translateY(0);
    background: #fff;
  }
} 

@keyframes preloaderlight {
  0% {
    height: 5px;
    transform: translateY(0);
    background: #fff;
  }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: #fff;
  }
  50%, 100% {
    height: 5px;
    transform: translateY(0);
    background: #fff;
  }
}



#spinner-loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgb(226, 230, 236);
  border-radius: 50%;
  border-top-color: #2569e7;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}