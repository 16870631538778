/* DateTimePicker.css */
.react-datetime-picker {
    position: relative; /* Ensure relative positioning for child elements */
  }
  
  .react-datetime-picker input {
    z-index: 10000 !important; /* Set a high z-index for the input field */
  }
  
  /* Add other styling as needed */
  