.label {
  height: 60.68px !important;
}
.permanent-underline {
  position: relative;
  text-decoration: none; /* Remove default underline */
  color: #007bff; /* Bootstrap primary color */
}

.permanent-underline::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px; /* Thickness of the underline */
  bottom: -3px; /* Position it below the text */
  left: 0;
  background-color: #009ef7; /* Same color as the text */
  transition: opacity 0.3s ease;
}

.permanent-underline:hover {
  color: #007bff; /* Maintain color on hover */
}

.permanent-underline:hover::after {
  opacity: 0; /* Remove underline on hover */
}



.shadowBox {
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 17%), 5px -3px 18px 5px rgb(37 45 51 / 17%);
}

p.no-margin {
  margin-bottom: 0;
  /* or margin-bottom: auto; */
}

.hrLine {
  border: none !important;
  width: 41 !important;
  height: 2 !important;
  color: "#007bfc" !important;
  background-color: "#007bfc" !important;
  margin: 0 auto !important;
}

.activeBorder {
  box-shadow: -2px 5px 10px 5px rgb(37 45 51 / 8%), 5px -3px 18px 5px rgb(37 45 51 / 8%);
  border: 2px solid #3498db !important;
}

.w100 {
  width: 100% !important;
}

.mhc_res_size {
  width: 828px;
}

.mhc_checkout2 {
  z-index: 95 !important;
  /* position: fixed !important; */
  top: 97px !important;
  width: 268px !important;
  left: 1151.8px !important;
  
}

.mhc_checkout {
  /* position: fixed !important;  */
  /* transition: top 0.3s ease-in-out; */
  /* top: 35vh; */
}

.mhc_checkout-margin-top {
     top: 10vh;
     position: fixed;
     width: 267.75px;
  }

.plan_list_active {
  outline: 2px solid #009EF7;
}

.border-left-right-bottom {
  border-left: 1px solid #e4e6ef !important;
  border-right: 1px solid #e4e6ef !important;
  border-bottom: 1px solid #e4e6ef !important;
}


@-webkit-keyframes loadStack {
  from {
    transform: translateX(-600%);
  }

  to {
    transform: translateX(200px);
  }
}

@keyframes loadStack {
  from {
    transform: translateX(-600%);
  }

  to {
    transform: translateX(200px);
  }
}

.loadingStack {
  background-color: lightgrey;
  border-radius: 5px;
  height: 20px;
  margin: 2em auto;
  overflow: hidden;
  position: relative;
  width: 400px;
}

.loadingStack:before {
  -webkit-animation: loadStack 2s ease-in-out infinite;
  animation: loadStack 2s ease-in-out infinite;
  background-image: repeating-linear-gradient(100deg, #1c1, #1c1 5px, #0a0 5px, #0a0 8px, #2d2 8px, #2d2 10px);
  border-radius: 5px;
  content: "";
  height: 100%;
  position: absolute;
  width: 10%;
}

.card_deploy {
  background-color: #fff;
  border-radius: 1rem;
  border: 0.5px solid rgba(128, 128, 128, 0.285);
  text-align: center;
  width: max-content;
  margin-right: 4rem;
}

.flag {
  margin: 0.2rem;
  background-color: white;
  border-radius: 10px 10px 0 0;
  padding: 0.5rem;
  height: 6rem;
}

.card_deploy_primary {
  background-color: #009ef7;
  border-radius: 1rem;
  border: 1.3px solid #009ef7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  text-align: center;
  width: max-content;
  margin-right: 4rem;
}

.os-card {
  background-color: #fff;
  border-radius: 1rem;
  border: 0.5px solid rgba(128, 128, 128, 0.285);
  text-align: center;
  margin-bottom: 5rem;
}

.os-card-white {
  background-color: #fff;
  border-radius: 1rem;
  width: 17rem;
  border: 2px solid #009ef7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  text-align: center;
  margin-bottom: 5rem;

}

.os-card-primary {
  background-color: #009ef7;
  border-radius: 1rem;
  width: 17rem;
  border: 5px solid #009ef7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  text-align: center;
  margin-bottom: 5rem;
}

.icon-os {
  margin: auto;
  background-color: white;
  border-radius: 10px 0 0 10px;
  padding: 0.95rem;
}

.row-os {
  --bs-gutter-x: 0.6rem;
  --bs-gutter-y: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(0* var(--bs-gutter-y));
  margin-right: calc(0* var(--bs-gutter-x));
  margin-left: calc(-5* var(--bs-gutter-x));
}

/* 
.text {
  display: block;
  margin-block-start: 2.5rem;
  margin-block-end: 2rem;
  margin-inline-start: -23px;
  margin-inline-end: 21px;
  unicode-bidi: isolate;
  font-size: 27px;
  color: white;
}

.text-primary {
  display: block;
  margin-block-start: 2.5rem;
  margin-block-end: 2rem;
  margin-inline-start: -23px;
  margin-inline-end: 42px;
  unicode-bidi: isolate;
  font-size: 20px;
  color: black;
} */
.black-text {
  color: black;
  font-size: 1.3rem;
  margin-top: 1rem;
}

.white-text {
  color: white;
  font-size: 1.3rem;
  margin-top: 1rem;
}

.os-lable {
  position: relative;
  border-radius: 5px;
  height: auto;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.selct-os-version {
  position: relative;
  width: 16.8rem;
  right: 1.2rem;
  background-color: #b3e2fd !important;
}

.text-firewall {
  margin-left: 0rem;
  background-color: #fff;
  border-radius: 0.2rem;
  width: 25rem;
  height: 3rem;
  border: 1.97px solid #009EF7;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); */
  /* text-align: center; */
  margin-bottom: 1.5rem;
}

.p-fire {
  margin-top: auto;
  margin-left: auto;
  color: #009EF7;
}

/* Create your Cloud Instances css start from here */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.group-81 {
  position: relative;
  width: 100%;
  height: 180px;
  box-sizing: border-box;
}

.group-57 {
  position: absolute;
  top: 6.78px;
  left: 0px;
  width: 100%;
  height: 127.47px;
  border: 0px solid rgba(191, 226, 241, 0.4);
  border-radius: 6.71px;
  box-sizing: border-box;
  background-color: rgba(63, 66, 84, 1);
  box-shadow: 0px 3.5px 3.5px rgba(0, 0, 0, 0.25);
}

.group-31 {
  width: 100%;
  height: 100%;
  border: 0px solid rgba(191, 226, 241, 0.4);
  border-radius: 6.71px;
  box-sizing: border-box;
  background-color: rgba(63, 66, 84, 1);
  box-shadow: 0px 3.5px 3.5px rgba(0, 0, 0, 0.25);
}

.frame-15 {
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 19.18px;
  width: 730.51px;
  height: 100%;
  box-sizing: border-box;
}

.create-your-cloud-insta {
  color: rgba(255, 255, 255, 1);
  font-size: 41.85px;
  line-height: 41.85px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  right: -80px;
}

.flat-isometric-3d-illustration-database-concept-with-cloud-server-_-186604528-removebg-preview-1 {
  position: absolute;
  top: -25px;
  width: auto;
  height: auto;
  right: 20px;
}

/* Create your Cloud Instances css end from here */

/* backup css started form here */
.backup-text-1 {
  font-weight: 500;
  font-size: 15px;
  color: #009EF7;
  margin-left: 10rem;
}

.span-align-1 {
  margin-left: 45rem;
  margin-top: 4rem;
}

.text-width {
  width: 41rem;
  font-size: 25px;
}

.new-img-backup {
  height: 350px;
  width: 350px;
  margin-top: -23rem;
  margin-left: 38rem;
}

/* backup css end form here */

/* price time css start */
.Plan-card{
  background-color: white;
  display: flex;
  border-radius: 1rem;
  width: 16rem;
  height: 8rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-bottom: 1rem;
  margin: 0rem;
}
.Plan-card-primary{
  border: 5px solid #009EF7;
    display: flex;
    border-radius: 1rem;
    width: 16rem;
    height: 8rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-bottom: 1rem;
    margin: 0rem;
}
.text-plan{
  font-size: 25px;
  width: 9rem;
  margin-top: 2rem;
  margin-left: 3rem;
}
.text-plan-primary{
  margin-left: 0.2rem;
  margin-top: 2rem;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 2rem;
  width: 16rem;
}
.plan-bg{
width: 15rem;
background-color:rgba(128, 128, 128, 0.285);
height: 2rem;
border-radius: 13px 13px 0 0;
}
.plan-bg-primary{
  background-color: #009EF7;
  width: 15.5rem;
  height: 2rem;
  border-radius: 1px 1px 0 0;
}
.row-discount{
  display: flex;
  flex-wrap: wrap;
  margin-left: 4.5rem;
}
.backup-bg-1{
  height:20rem;
}
.label-plan {
  margin-top: 0rem;
  margin-left: 8rem;
}
/* price time css end */